import http from "../http-common";

class MerimenDataService {
  // Get all records
  getAll() {
    return http.get("/merimenData");
  }

  // Get single record
  get(id) {
    return http.get(`/merimenData/${id}`);
  }

  // Create new record
  create(data) {
    return http.post("/merimenData", data);
  }

  // Update single record
  update(id, data) {
    return http.put(`/merimenData/${id}`, data);
  }

  // Update status of single record
  updateStatus(id, status) {
    return http.put(`/merimenData/${id}/status`, { status });
  }

  // Delete single record
  delete(id) {
    if (!id) throw new Error("ID is required for deletion");
    return http.delete(`/merimenData/${id}`);
  }

  // Bulk delete records
  bulkDelete(ids) {
    // console.log("ids:", ids);
    if (!ids || !ids.length) {
      throw new Error("IDs are required for bulk deletion");
    }
    return http.delete("/merimenData/batch/delete", {
      data: { ids },
    });
  }

  // Bulk update status
  bulkUpdateStatus(ids, status) {
    return http.put("/merimenData/bulk/status", { ids, status });
  }

  // Accept single record and move to casefiles
  accept(id) {
    return http.post(`/merimenData/${id}/accept`);
  }

  // Bulk accept records and move to casefiles
  bulkAccept(ids) {
    return http.post("/merimenData/bulk/accept", { ids });
  }

  // Get verification status
  getVerificationStatus() {
    return http.get("/merimenData/verification-status");
  }
}

export default new MerimenDataService();
