<template>
  <v-container fluid style="width: 80%">
    <div>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="merimenData"
        :search="search"
        show-select
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-options': [10, 20, 30, 40, 50, 100, -1],
        }"
        :items-per-page="50"
      >
        <template v-slot:top>
          <v-toolbar flat class="mobile-responsive-toolbar">
            <!-- Action Buttons Group -->
            <v-responsive
              max-width="100%"
              class="d-flex flex-wrap align-center"
            >
              <div class="d-flex flex-wrap align-center mb-2 mb-sm-0">
                <v-btn
                  :disabled="!selected.length"
                  small
                  tile
                  color="success"
                  class="mr-2 mb-2 mb-sm-0"
                  @click="bulkAcceptConfirmation"
                >
                  <v-icon left small>mdi-check-all</v-icon>
                  <span class="d-none d-sm-inline">Accept Selected</span>
                  <span class="d-inline d-sm-none">Accept</span>
                </v-btn>
                <v-btn
                  :disabled="!selected.length"
                  small
                  tile
                  color="error"
                  class="mr-2 mb-2 mb-sm-0"
                  @click="bulkDeleteConfirmation"
                >
                  <v-icon left small>mdi-delete</v-icon>
                  <span class="d-none d-sm-inline">Delete Selected</span>
                  <span class="d-inline d-sm-none">Delete</span>
                </v-btn>
              </div>

              <!-- Spacer for larger screens -->
              <v-spacer class="d-none d-md-block"></v-spacer>

              <!-- Search Field -->
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="mr-2 flex-grow-1"
                dense
              ></v-text-field>
            </v-responsive>

            <!-- Add snackbar for import status -->
            <!-- <v-snackbar
              v-model="showImportMessage"
              :color="importMessageColor"
              :timeout="6000"
              top
              right
            >
              {{ importMessage }}
              <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="showImportMessage = false">
                  Close
                </v-btn>
              </template>
            </v-snackbar> -->

            <!-- Edit Dialog (Verification Form) -->
            <v-dialog v-model="dialog" max-width="800px" persistent>
              <v-card>
                <v-card-title class="justify-center text-h5 grey lighten-2">
                  <span>Verify Record</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <!-- Department Section -->
                      <v-card width="100%">
                        <v-card-title>Department</v-card-title>
                        <v-col cols="12">
                          <v-select
                            v-model="editedItem.refType"
                            :items="departments"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            hint="Select Department"
                            persistent-hint
                            label="Department"
                            @change="handleDepartmentChange"
                          >
                            <template #label>
                              <span class="red--text"><strong>* </strong></span
                              >Department
                            </template>

                            <!-- Add this template for append-outer -->
                            <template v-slot:append-outer>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    small
                                    outlined
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    {{
                                      merimenData[editedIndex]?.claimType ||
                                      "No Merimen Data"
                                    }}
                                  </v-chip>
                                </template>
                                <span>Original Claim Type from Merimen</span>
                              </v-tooltip>
                            </template>
                          </v-select>
                        </v-col>
                        <!-- Add matching info hint -->
                        <!-- <v-col cols="12" v-if="editedItem.claimType">
                          <v-alert
                            dense
                            text
                            color="info"
                            border="left"
                            class="caption"
                          >
                            Matching: Merimen Claim Type "{{
                              editedItem.claimType
                            }}" with Department
                          </v-alert>
                        </v-col> -->
                        <v-col cols="12">
                          <v-select
                            v-model="editedItem.subRefType"
                            :items="filtersubRefType(editedItem.refType)"
                            item-text="subCode"
                            item-value="id"
                            no-data-text="There is no file-Classification option for selected department"
                            :menu-props="{ maxHeight: '400' }"
                            label="File-Classification"
                            hint="Select File-Classification"
                            persistent-hint
                          ></v-select>
                        </v-col>
                      </v-card>

                      <!-- Branch & Insurer Section -->
                      <v-col cols="12">
                        <v-select
                          v-model="editedItem.branch"
                          :items="branch"
                          item-text="name"
                          item-value="id"
                          :menu-props="{ maxHeight: '400' }"
                          hint="Set the casefile branch (Optional)"
                          persistent-hint
                          label="Branch"
                          clearable
                          @change="(val) => (editedItem.branch = val || null)"
                        ></v-select>
                      </v-col>

                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editedItem.handlingInsurer"
                          :items="insurers"
                          item-text="name"
                          item-value="name"
                          :menu-props="{ maxHeight: '400' }"
                          hint="Set the casefile insurer"
                          persistent-hint
                          label="Insurer"
                          :rules="[(v) => !!v || 'Insurer is required']"
                        >
                          <template #label>
                            <span class="red--text"><strong>* </strong></span
                            >Insurer
                          </template>
                          <!-- Modified append-outer template to use original data -->
                          <template v-slot:append-outer>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  small
                                  outlined
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{
                                    merimenData[editedIndex]?.handlingInsurer ||
                                    "No Merimen Data"
                                  }}
                                </v-chip>
                              </template>
                              <span>Original Insurer from Merimen</span>
                            </v-tooltip>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <!-- Add matching info hint -->
                      <!-- <v-col cols="12" v-if="editedItem.handlingInsurer">
                        <v-alert
                          dense
                          text
                          color="info"
                          border="left"
                          class="caption"
                        >
                          Matching: Merimen Insurer "{{
                            editedItem.handlingInsurer
                          }}"
                        </v-alert>
                      </v-col> -->

                      <v-col cols="12">
                        <v-select
                          v-model="editedItem.handler"
                          :items="filterHandler(editedItem.handlingInsurer)"
                          item-text="name"
                          item-value="id"
                          no-data-text="There is no handler option for selected insurer"
                          placeholder="Please select a handler"
                          :menu-props="{ maxHeight: '400' }"
                          label="Claim Handler"
                          hint="Select Handler"
                          persistent-hint
                        >
                          <template v-slot:selection="{ item }">
                            {{ item.name }}
                          </template>
                        </v-select>
                      </v-col>

                      <!-- Claim Details -->
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="editedItem.insuranceClaimNo"
                          label="Insurers Reference"
                          :rules="[
                            (v) => !!v || 'Insurers reference is required',
                          ]"
                        >
                          <template #label>
                            <span class="red--text"><strong>* </strong></span
                            >Insurers Reference
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-menu
                          v-model="assignmentDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="formattedAssignmentDate"
                              label="Date of Assignment"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="[
                                (v) => !!v || 'Assignment date is required',
                              ]"
                            >
                              <template #label>
                                <span class="red--text"
                                  ><strong>* </strong></span
                                >Date of Assignment
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.assignmentDate"
                            @input="assignmentDateMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <!-- Vehicle & Loss Details -->
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="editedItem.insuredVehRegNo"
                          label="Vehicle Number"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-menu
                          v-model="dateOfLossMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="formattedLossDate"
                              label="Date of Loss"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="[
                                (v) => !!v || 'Date of loss is required',
                              ]"
                            >
                              <template #label>
                                <span class="red--text"
                                  ><strong>* </strong></span
                                >Date of Loss
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.dateOfLoss"
                            @input="dateOfLossMenu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <!-- Place of Loss -->
                      <v-card width="100%">
                        <v-card-title>Place Of Loss</v-card-title>
                        <v-col cols="12">
                          <v-select
                            v-model="editedItem.stateOfLoss"
                            :items="state"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ maxHeight: '400' }"
                            hint="Select State"
                            persistent-hint
                            label="State"
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="editedItem.placeOfLoss"
                            :items="filterRegions(editedItem.stateOfLoss)"
                            item-text="name"
                            item-value="name"
                            no-data-text="There is no district option for selected state"
                            :menu-props="{ maxHeight: '400' }"
                            label="Area Of Loss"
                            hint="Select District"
                            persistent-hint
                          ></v-select>
                        </v-col>
                      </v-card>

                      <!-- Insured Details -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.insuredName"
                          label="Insured Name"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-textarea
                          v-model="editedItem.adjAsgRemarks"
                          label="Insurers Comment"
                        >
                        </v-textarea>
                        <!-- <v-textarea
                          v-model="editedItem.adjAsgRemarks"
                          label="Insurers Comment"
                          :rules="[
                            (v) => !!v || 'Insurers comment is required',
                          ]"
                        >
                          <template #label>
                            <span class="red--text"><strong>* </strong></span
                            >Insurers Comment
                          </template>
                        </v-textarea> -->
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions class="grey lighten-3">
                  <v-spacer></v-spacer>
                  <v-btn outlined small tile @click="close">Cancel</v-btn>
                  <v-btn
                    small
                    tile
                    color="success"
                    @click="acceptItem"
                    :disabled="!isFormValid"
                  >
                    Accept
                  </v-btn>
                  <v-btn
                    small
                    tile
                    color="error"
                    @click="deleteItem(editedItem)"
                  >
                    Deny
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Bulk Action Confirmation Dialogs -->
            <v-dialog v-model="dialogBulkAccept" max-width="400px">
              <v-card>
                <v-card-title class="justify-center text-h5 grey lighten-2">
                  Accept Selected Records?
                </v-card-title>
                <v-card-text class="pt-4">
                  Are you sure you want to accept {{ selected.length }} records?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn outlined small tile @click="dialogBulkAccept = false"
                    >No</v-btn
                  >
                  <v-btn small tile color="success" @click="bulkAcceptConfirm"
                    >Yes</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Replace the existing Bulk Delete Dialog with this enhanced version -->
            <v-dialog v-model="dialogBulkDelete" max-width="500px">
              <v-card>
                <v-card-title class="justify-center text-h5 error white--text">
                  <v-icon left color="white">mdi-delete-alert</v-icon>
                  Bulk Deny Merimen Records
                </v-card-title>
                <v-card-text class="pt-4">
                  <v-container>
                    <v-alert
                      dense
                      outlined
                      type="warning"
                      border="left"
                      class="mb-3"
                      color="error"
                    >
                      This action will permanently remove
                      {{ selected.length }} records from Merimen Data.
                    </v-alert>

                    <v-sheet class="pa-3 mb-3" outlined>
                      <div class="text-subtitle-1 mb-2">
                        Selected Records Summary:
                      </div>
                      <v-list dense class="transparent">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="primary">mdi-file-multiple</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Total Records</v-list-item-title>
                            <v-list-item-subtitle>{{
                              selected.length
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="primary">mdi-office-building</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              >Insurers Affected</v-list-item-title
                            >
                            <v-list-item-subtitle>
                              {{
                                [
                                  ...new Set(
                                    selected.map((item) => item.handlingInsurer)
                                  ),
                                ].length
                              }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="primary"
                              >mdi-file-document-multiple</v-icon
                            >
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Claim Types</v-list-item-title>
                            <v-list-item-subtitle>
                              {{
                                [
                                  ...new Set(
                                    selected.map((item) => item.claimType)
                                  ),
                                ].join(", ")
                              }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-sheet>

                    <v-divider class="my-3"></v-divider>
                    <p class="text-center red--text text--darken-1">
                      Are you sure you want to deny these records?<br />
                      <span class="caption">This action cannot be undone.</span>
                    </p>
                  </v-container>
                </v-card-text>
                <v-card-actions class="pb-6 px-6">
                  <v-spacer></v-spacer>
                  <v-btn outlined tile @click="dialogBulkDelete = false">
                    <v-icon left>mdi-close</v-icon>
                    Cancel
                  </v-btn>
                  <v-btn
                    color="error"
                    dark
                    tile
                    class="ml-3"
                    @click="bulkDeleteConfirm"
                  >
                    <v-icon left>mdi-delete-forever</v-icon>
                    Deny {{ selected.length }} Records
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Delete Confirmation Dialog -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="justify-center text-h5 error white--text">
                  <v-icon left color="white">mdi-alert</v-icon>
                  Deny Merimen Record
                </v-card-title>
                <v-card-text class="pt-4">
                  <v-container>
                    <v-alert
                      dense
                      outlined
                      type="warning"
                      border="left"
                      class="mb-3"
                      color="error"
                    >
                      This action will permanently remove this record from
                      Merimen Data.
                    </v-alert>

                    <v-list dense class="transparent">
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="primary">mdi-identifier</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="font-weight-medium"
                            >Case ID</v-list-item-title
                          >
                          <v-list-item-subtitle>{{
                            editedItem.merimenCaseId || "N/A"
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="primary"
                            >mdi-file-document-outline</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="font-weight-medium"
                            >Claim Number</v-list-item-title
                          >
                          <v-list-item-subtitle>{{
                            editedItem.insuranceClaimNo || "N/A"
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="primary">mdi-office-building</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="font-weight-medium"
                            >Insurer</v-list-item-title
                          >
                          <v-list-item-subtitle>{{
                            editedItem.handlingInsurer || "N/A"
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="primary">mdi-car</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="font-weight-medium"
                            >Vehicle Number</v-list-item-title
                          >
                          <v-list-item-subtitle>{{
                            editedItem.insuredVehRegNo || "N/A"
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <v-divider class="my-3"></v-divider>
                    <p class="text-center red--text text--darken-1">
                      Are you sure you want to deny this record?
                    </p>
                  </v-container>
                </v-card-text>
                <v-card-actions class="pb-6 px-6">
                  <v-spacer></v-spacer>
                  <v-btn outlined tile @click="closeDelete">
                    <v-icon left>mdi-close</v-icon>
                    Cancel
                  </v-btn>
                  <v-btn
                    color="error"
                    dark
                    tile
                    class="ml-3"
                    @click="deleteItemConfirm"
                  >
                    <v-icon left>mdi-delete</v-icon>
                    Deny Record
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatCreatedAt(item.createdAt) }}
        </template>
        <!-- Action buttons for each row -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>verify</span>
          </v-tooltip>
          <v-tooltip bottom transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                color="success"
                @click="acceptSingle(item)"
                disabled
              >
                mdi-check
              </v-icon>
            </template>
            <span>accept</span>
          </v-tooltip>
          <v-tooltip right transition="fab-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                color="red darken-1"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>deny</span>
          </v-tooltip>
        </template>

        <!-- Add this new template slot for numbering -->
        <template v-slot:[`item.numbering`]="{ index }">
          {{ index + 1 }}
        </template>
        <!-- Add template for insurer name with tooltip -->
        <template v-slot:[`item.handlingInsurer`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ truncateText(item.handlingInsurer || "N/A", 20) }}
              </span>
            </template>
            <span>{{ item.handlingInsurer || "N/A" }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import MerimenDataService from "../../services/MerimenDataService";
import CasefileDataService from "../../services/CasefileDataService";
import DepartmentDataService from "../../services/DeptDataService";
import InsurerDataService from "../../services/InsDataService";
import StateDataService from "../../services/StateDataService";
import RegionDataService from "../../services/RegionDataService";
import HandlerDataService from "../../services/HandlerDataService";
import SubRefTypeDataService from "../../services/SubDeptDataService";
import BranchDataService from "../../services/BranchDataService";

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: "",
    merimenData: [],
    statusOptions: ["New", "In Progress", "Completed", "Cancelled"],
    dateMenu: false,
    dateOfLossMenu: false,
    claimActions: [
      "New Claim",
      "Follow Up",
      "Investigation",
      "Settlement",
      "Closed",
    ],
    claimTypes: [
      "Motor",
      "Fire",
      "Marine",
      "Personal Accident",
      "Workmen Compensation",
      "Other",
    ],
    headers: [
      {
        text: "NO.",
        align: "start",
        sortable: false,
        value: "numbering",
      },
      { text: "CASE ID", value: "merimenCaseId" },
      { text: "CLAIM TYPE", value: "claimType" },
      { text: "CLAIM NUMBER", value: "insuranceClaimNo" },
      { text: "VEHICLE NUMBER", value: "insuredVehRegNo" },
      { text: "INSURER", value: "handlingInsurer" },
      { text: "RECEIVED", value: "createdAt" },
      { text: "ACTIONS", value: "actions", sortable: false },
    ],
    defaultItem: {
      id: null,
      merimenCaseId: "",
      claimType: "",
      insuranceClaimNo: "",
      policyCoverNoteNo: "",
      status: "New",
      dateReceived: new Date().toISOString().substr(0, 10),
      remarks: "",
      claimAction: "",
      insuredName: "",
      insuredIdNo: "",
      insuredTel: "",
      insuredVehRegNo: "",
      dateOfLoss: null,
      timeOfLoss: null,
      handlingInsurer: "",
      claimantInsurer: "",
      placeOfLossPostcode: "",
      recommendedReserve: null,
      adjProviFee: null,
      adjAsgRemarks: "",
      assignmentDate: new Date().toISOString().split("T")[0],
      refType: null,
      subRefType: null,
      handler: null,
      branch: null,
      stateOfLoss: null,
      placeOfLoss: "",
    },
    editedIndex: -1,
    editedItem: {
      id: null,
      merimenCaseId: "",
      claimType: "",
      insuranceClaimNo: "",
      policyCoverNoteNo: "",
      status: "New",
      dateReceived: new Date().toISOString().substr(0, 10),
      remarks: "",
      claimAction: "",
      insuredName: "",
      insuredIdNo: "",
      insuredTel: "",
      insuredVehRegNo: "",
      dateOfLoss: null,
      timeOfLoss: null,
      handlingInsurer: "",
      claimantInsurer: "",
      placeOfLossPostcode: "",
      recommendedReserve: null,
      adjProviFee: null,
      adjAsgRemarks: "",
      stateOfLoss: null,
      placeOfLoss: "",
      handler: null,
      branch: null,
      assignmentDate: new Date().toISOString().split("T")[0],
      refType: null,
      subRefType: null,
    },
    selected: [],
    dialogBulkAccept: false,
    dialogBulkDelete: false,
    departments: [], // Add this
    insurers: [], // Add this
    policeReportDateMenu: false,
    assignmentDateMenu: false,
    state: [],
    regions: [],
    subRefTypes: [], // Add this property
    branch: [], // Add this property
    handlers: [], // Make sure this is initialized
    loading: true, // Add loading state
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Record" : "Edit Record";
    },
    isAddButtonDisabled() {
      return !this.editedItem.caseId;
    },

    isFormValid() {
      const requiredFields = [
        this.editedItem.refType, // Department
        this.editedItem.handlingInsurer, // Insurer
        this.editedItem.insuranceClaimNo, // Insurers Reference
        this.editedItem.assignmentDate, // Date of Assignment
        this.editedItem.dateOfLoss, // Date of Loss
        // this.editedItem.adjAsgRemarks, // Insurers Comment
      ];

      return requiredFields.every(
        (field) => field !== null && field !== undefined && field !== ""
      );
    },

    formattedAssignmentDate: {
      get() {
        return this.editedItem.assignmentDate
          ? this.formatDateMethod(this.editedItem.assignmentDate)
          : "";
      },
      set(value) {
        this.editedItem.assignmentDate = value;
      },
    },

    formattedLossDate: {
      get() {
        return this.editedItem.dateOfLoss
          ? this.formatDateMethod(this.editedItem.dateOfLoss)
          : "";
      },
      set(value) {
        this.editedItem.dateOfLoss = value;
      },
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    editedItem: {
      deep: true,
      handler() {
        this.$forceUpdate();
      },
    },
    "editedItem.refType": {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      },
    },
    "editedItem.handlingInsurer": {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      },
    },
  },

  methods: {
    // formatCreatedAt(date) {
    //   if (!date) return null;
    //   return moment(date).format("DD/MM/YYYY");
    // },
    formatCreatedAt(value) {
      if (!value) return "";
      const date = new Date(value);
      return `${date.getDate().toString().padStart(2, "0")}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${date.getFullYear()} (${date
        .getHours()
        .toString()
        .padStart(2, "0")}.${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}hrs)`;
    },

    retrieveData() {
      MerimenDataService.getAll()
        .then((response) => {
          this.merimenData = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    refreshList() {
      this.retrieveData();
    },

    editItem(item) {
      this.editedIndex = this.merimenData.indexOf(item);
      this.editedItem = Object.assign({}, this.defaultItem, {
        ...item,
        refType: null,
        handlingInsurer: null,
        handler: null,
        branch: null,
        subRefType: null,
        claimType: item.claimType,
        assignmentDate:
          item.assignmentDate || new Date().toISOString().split("T")[0],
      });

      this.dialog = true;
    },

    // Show delete confirmation dialog
    deleteItem(item) {
      if (!item.id) {
        console.error("No item ID provided");
        return;
      }
      this.editedIndex = this.merimenData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    // Delete after confirmation
    async deleteItemConfirm() {
      try {
        if (!this.editedItem.id) {
          throw new Error("No ID found for deletion");
        }
        await MerimenDataService.delete(this.editedItem.id);
        this.$store.dispatch("setSnackbar", {
          showing: true,
          color: "error",
          text: "Record denied successfully",
        });
        this.refreshList();
        this.closeDelete();
        this.dialog = false;
      } catch (e) {
        console.error("Delete error:", e);
        this.$store.dispatch("setSnackbar", {
          showing: true,
          color: "error",
          text: "Error denying record",
        });
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // Update existing record
        MerimenDataService.update(this.editedItem.id, this.editedItem)
          .then((response) => {
            this.close();
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "warning",
              text:
                (response && response.data && response.data.message) ||
                "Record updated successfully",
            });
            this.refreshList();
          })
          .catch((e) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "error",
              text:
                (e.response && e.response.data && e.response.data.message) ||
                "Error updating record",
            });
          });
      } else {
        // Create new record
        MerimenDataService.create(this.editedItem)
          .then((response) => {
            this.editedItem.id = response.data.id;
            this.close();
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                "Record created successfully",
            });
            this.refreshList();
          })
          .catch((e) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "error",
              text:
                (e.response && e.response.data && e.response.data.message) ||
                e.message ||
                "Error creating record",
            });
          });
      }
      this.close();
    },

    async fetchDepartments() {
      try {
        const response = await DepartmentDataService.getAll();
        this.departments = response.data;
      } catch (e) {
        console.error("Error fetching departments:", e);
      }
    },

    async fetchInsurers() {
      try {
        const response = await InsurerDataService.getAll();
        this.insurers = response.data;
      } catch (e) {
        console.error("Error fetching insurers:", e);
      }
    },

    findDepartmentId(claimType) {
      if (!claimType) {
        console.warn("No claim type provided, using default department");
        return this.departments[0]?.id;
      }

      const dept = this.departments.find(
        (d) => d.name.toLowerCase() === claimType.toLowerCase()
      );

      if (!dept) {
        console.warn(
          `No matching department found for ${claimType}, using default`
        );
        return this.departments[0]?.id;
      }

      return dept.id;
    },

    findInsurerId(insurerName) {
      if (!insurerName) {
        console.warn("No insurer name provided, using default insurer");
        return this.insurers[0]?.id;
      }

      const insurer = this.insurers.find(
        (i) => i.name.toLowerCase() === insurerName.toLowerCase()
      );

      if (!insurer) {
        console.warn(
          `No matching insurer found for ${insurerName}, using default`
        );
        return this.insurers[0]?.id;
      }

      return insurer.id;
    },

    // Add this new helper method
    formatDate(dateString) {
      if (!dateString) return null;
      try {
        // Handle "MM/DD/YYYY HH:mm:ss AM/PM" format
        const parts = dateString.split(" ");
        if (parts.length >= 1) {
          const dateParts = parts[0].split("/");
          if (dateParts.length === 3) {
            // Rearrange from M/D/YYYY to YYYY-MM-DD
            const month = dateParts[0].padStart(2, "0");
            const day = dateParts[1].padStart(2, "0");
            const year = dateParts[2];
            return `${year}-${month}-${day}`;
          }
        }

        // Fallback to default date parsing if above format doesn't match
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return null;
        return date.toISOString().split("T")[0];
      } catch (e) {
        console.error("Error formatting date:", dateString, e);
        return null;
      }
    },

    // Add helper method to format all dates consistently
    formatDateForDisplay(dateString) {
      if (!dateString) return null;
      try {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return null;
        return date.toISOString().split("T")[0];
      } catch (e) {
        console.error("Error formatting date:", e);
        return null;
      }
    },

    transformToCasefileData(merimenData) {
      try {
        if (!merimenData) {
          throw new Error("No data provided for transformation");
        }

        // Validate department selection
        if (!merimenData.refType) {
          throw new Error("Department must be selected");
        }

        // Find the selected department to verify it exists
        const selectedDepartment = this.departments.find(
          (dept) => dept.id === merimenData.refType
        );
        if (!selectedDepartment) {
          throw new Error(
            `Invalid department selection: ${merimenData.refType}`
          );
        }

        // console.log("Department mapping:", {
        //   selectedId: merimenData.refType,
        //   departmentFound: selectedDepartment,
        //   originalClaimType: merimenData.claimType,
        // });

        // Create transformed data object using validated department
        const transformedData = {
          refType: merimenData.refType,
          subRefType: merimenData.subRefType, // Add this line
          claimNo: merimenData.insuranceClaimNo?.trim() || null,
          insuredName: merimenData.insuredName?.trim() || null,
          insuredIC: merimenData.insuredIdNo?.trim() || null,
          insuredTel: merimenData.insuredTel?.trim() || null,
          vehicleNo: merimenData.insuredVehRegNo?.trim() || null,
          dateOfLoss: this.formatDateForDisplay(merimenData.dateOfLoss),
          timeOfLoss: merimenData.timeOfLoss?.trim() || null,
          policyNo: merimenData.policyCoverNoteNo?.trim() || null,
          insurer: this.findInsurerId(merimenData.handlingInsurer),
          dateOfAssign:
            this.formatDateForDisplay(merimenData.assignmentDate) ||
            this.formatDateForDisplay(new Date()),
          insComment: merimenData.adjAsgRemarks?.trim() || null,
          fileStatus: "NEW",
          flag: 1,
          branch: merimenData.branch || null, // Ensure branch is optional
          handler: merimenData.handler,
          stateOfLoss: merimenData.stateOfLoss || null, // Make stateOfLoss optional too
          placeOfLoss: merimenData.placeOfLoss?.trim() || null,
        };

        // Add debug log for branch
        // console.log("Branch value being sent:", transformedData.branch);

        // console.log("Transformed data:", transformedData);
        return transformedData;
      } catch (error) {
        console.error("Data transformation error:", error);
        throw error;
      }
    },

    async acceptItem() {
      try {
        // Ensure reference data is loaded
        if (!this.departments.length || !this.insurers.length) {
          await Promise.all([this.fetchDepartments(), this.fetchInsurers()]);
        }

        // Log the original data
        // console.log("Original Merimen data:", this.editedItem);

        // Transform and validate data
        const casefileData = this.transformToCasefileData(this.editedItem);

        // First create the casefile
        const createResponse = await CasefileDataService.create(casefileData);
        // console.log("Casefile created:", createResponse);

        // Only delete from Merimen if casefile creation was successful
        if (createResponse && createResponse.data) {
          await MerimenDataService.delete(this.editedItem.id);

          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "success",
            text: "Record accepted and moved to casefiles successfully",
          });
        } else {
          throw new Error("Casefile creation response was invalid");
        }

        this.refreshList();
        this.close();
      } catch (e) {
        console.error("Accept error details:", {
          message: e.message,
          response: e.response?.data,
          stack: e.stack,
        });

        // Show more detailed error message
        this.$store.dispatch("setSnackbar", {
          showing: true,
          color: "error",
          text: `Error: ${
            e.response?.data?.message || e.message || "Server error occurred"
          }`,
        });
      }
    },

    async acceptSingle(item) {
      try {
        // Ensure reference data is loaded
        if (!this.departments.length || !this.insurers.length) {
          await Promise.all([this.fetchDepartments(), this.fetchInsurers()]);
        }

        const casefileData = this.transformToCasefileData(item);
        await CasefileDataService.create(casefileData);
        await MerimenDataService.delete(item.id);

        this.$store.dispatch("setSnackbar", {
          showing: true,
          color: "success",
          text: "Record accepted and moved to casefiles successfully",
        });
        this.refreshList();
      } catch (e) {
        console.error("Accept error:", e);
        this.$store.dispatch("setSnackbar", {
          showing: true,
          color: "error",
          text: "Error accepting and moving record",
        });
      }
    },

    async bulkAcceptConfirm() {
      try {
        // Ensure reference data is loaded
        if (!this.departments.length || !this.insurers.length) {
          await Promise.all([this.fetchDepartments(), this.fetchInsurers()]);
        }

        for (const item of this.selected) {
          const casefileData = this.transformToCasefileData(item);
          await CasefileDataService.create(casefileData);
          await MerimenDataService.delete(item.id);
        }

        this.$store.dispatch("setSnackbar", {
          showing: true,
          color: "success",
          text: "Selected records accepted and moved to casefiles successfully",
        });
        this.dialogBulkAccept = false;
        this.selected = [];
        this.refreshList();
      } catch (e) {
        console.error("Bulk accept error:", e);
        this.$store.dispatch("setSnackbar", {
          showing: true,
          color: "error",
          text: "Error processing bulk accept",
        });
      }
    },

    bulkAcceptConfirmation() {
      this.dialogBulkAccept = true;
    },

    bulkDeleteConfirmation() {
      if (this.selected.length > 0 && this.selected.every((item) => item.id)) {
        this.dialogBulkDelete = true;
      } else {
        this.$store.dispatch("setSnackbar", {
          showing: true,
          color: "error",
          text: "Invalid selection for deletion",
        });
      }
    },

    async bulkDeleteConfirm() {
      try {
        const ids = this.selected.map((item) => item.id);
        // console.log("IDs to delete:", ids); // Debug log

        if (!ids.length) {
          throw new Error("No IDs found for deletion");
        }

        await MerimenDataService.bulkDelete(ids);

        this.$store.dispatch("setSnackbar", {
          showing: true,
          color: "error",
          text: "Selected records denied successfully",
        });

        this.dialogBulkDelete = false;
        this.selected = [];
        this.refreshList();
      } catch (e) {
        console.error("Bulk delete error:", e);
        this.$store.dispatch("setSnackbar", {
          showing: true,
          color: "error",
          text: e.message || "Error denying selected records",
        });
      }
    },

    filterRegions(stateId) {
      if (!this.regions || !stateId) return [];
      return this.regions.filter((region) => region.branchId === stateId);
    },

    filterHandler(insurerName) {
      if (!this.handlers || !insurerName || !this.insurers) return [];

      // Find the insurer ID from the name
      const insurer = this.insurers.find(
        (ins) => ins.name.toLowerCase() === insurerName.toLowerCase()
      );

      if (!insurer) return [];

      // Filter handlers using the insurer ID
      return this.handlers.filter((handler) => handler.insId === insurer.id);
    },

    async retrieveStates() {
      try {
        const response = await StateDataService.getAll();
        this.state = response.data;
      } catch (e) {
        console.error("Error fetching states:", e);
      }
    },

    async retrieveRegions() {
      try {
        const response = await RegionDataService.getAll();
        this.regions = response.data;
      } catch (e) {
        console.error("Error fetching regions:", e);
      }
    },

    async retrieveHandlers() {
      try {
        const response = await HandlerDataService.getAll();
        this.handlers = response.data;
      } catch (e) {
        console.error("Error fetching handlers:", e);
      }
    },

    // Add this method
    filtersubRefType(deptId) {
      if (!this.subRefTypes || !deptId) {
        // console.log("No subRefTypes or deptId:", {
        //   subRefTypes: this.subRefTypes,
        //   deptId,
        // });
        return [];
      }
      const filtered = this.subRefTypes.filter(
        (subRefType) => subRefType.deptId === deptId
      );
      // console.log("Filtered subRefTypes:", { deptId, filtered });
      return filtered;
    },

    // Add this method to fetch subRefTypes
    async retrieveSubRefType() {
      try {
        const response = await SubRefTypeDataService.getAll();
        this.subRefTypes = response.data;
      } catch (e) {
        console.error("Error fetching sub-departments:", e);
      }
    },

    // Add this method
    async retrieveBranches() {
      try {
        const response = await BranchDataService.getAll();
        this.branch = response.data.map(this.getDisplayBranch);
      } catch (e) {
        console.error("Error fetching branches:", e);
      }
    },

    // Add helper method for formatting branch data
    getDisplayBranch(branch) {
      return {
        id: branch.id,
        name:
          branch.name.length > 30
            ? branch.name.substr(0, 30) + "..."
            : branch.name,
        brCode:
          branch.brCode.length > 30
            ? branch.brCode.substr(0, 30) + "..."
            : branch.brCode,
      };
    },
    formatDateMethod(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    // Add new method to handle department change
    handleDepartmentChange(deptId) {
      // Update claimType based on selected department
      const selectedDept = this.departments.find((d) => d.id === deptId);
      if (selectedDept) {
        this.editedItem.claimType = selectedDept.name;
      }

      // Force refresh of subRefType items
      this.$nextTick(() => {
        // Clear current selection
        this.editedItem.subRefType = null;
        // Log available subRefTypes for debugging
        // console.log("Available subRefTypes:", this.subRefTypes);
        // console.log("Selected department:", deptId);
        // console.log("Filtered subRefTypes:", this.filtersubRefType(deptId));
      });
    },
    // Add new helper method for text truncation
    truncateText(text, length) {
      if (!text) return "N/A";
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
  },

  async mounted() {
    try {
      this.loading = true;
      await Promise.all([
        this.retrieveData(),
        this.fetchDepartments(),
        this.fetchInsurers(),
        this.retrieveStates(),
        this.retrieveRegions(),
        this.retrieveHandlers(),
        this.retrieveSubRefType(), // Add this line
        this.retrieveBranches(), // Add this line
      ]);
    } catch (error) {
      console.error("Error loading data:", error);
      this.$store.dispatch("setSnackbar", {
        showing: true,
        color: "error",
        text: "Error loading data",
      });
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.v-card.grey.lighten-4 {
  border-left: 3px solid #1976d2;
}

.v-card-title {
  border-bottom: 1px solid #e0e0e0;
}

.required-field::before {
  content: "* ";
  color: red;
}

.v-chip.v-chip--outlined {
  border-style: dashed;
}

.caption {
  font-size: 0.75rem;
}

/* Add these styles to your existing <style> block */
.v-select.v-text-field--placeholder .v-select__selection--comma {
  color: rgba(0, 0, 0, 0.6);
}

.mobile-responsive-toolbar {
  flex-wrap: wrap;
  padding: 8px !important;
}

/* Make buttons more compact on mobile */
@media (max-width: 600px) {
  .mobile-responsive-toolbar .v-btn {
    min-width: 0;
    padding: 0 8px !important;
  }

  .mobile-responsive-toolbar .v-text-field {
    font-size: 14px;
  }

  .v-toolbar__content {
    flex-wrap: wrap;
    height: auto !important;
    padding: 8px;
  }
}

/* Ensure proper spacing between wrapped items */
.mobile-responsive-toolbar .v-responsive {
  gap: 8px;
}

/* Adjust container width for better mobile layout */
@media (max-width: 960px) {
  .container.fluid {
    width: 100% !important;
    padding: 8px;
  }
}
</style>
